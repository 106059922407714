import React from "react"
import Link from "gatsby-link"
// import CoffeeImg from '../../../static/img/coffee.png'

const EventCard = ({ events }) => {
  return (
    <div className="section">
      {events.map(({ node: event }) => (
        <div className="column" key={event.id} id="event-card">
          <h5>{event.frontmatter.title}</h5>
          <img
            src={event.frontmatter.cover}
            style={{
              display: "block",
              minHeight: `165px`,
              minWidth: `270px`,
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
            }}
          />
          <p>{event.frontmatter.meta_description}</p>
        </div>
      ))}
    </div>
  )
}

export default EventCard
