/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import PageWrapper from "../../layouts/PageWrapper"
import EventPost from "../../components/EventPost"
import HomeLogo from "../../components/HomeLogo"
import Pagination from "../../components/Pagination"

export default class EventPage extends Component {
  state = {
    currentPage: this.props.data.allMarkdownRemark.group.length,
  }

  handlePageChange = (page) => {
    this.setState({
      currentPage: page,
    })
  }

  render() {
    const { data } = this.props
    const { group: events } = data.allMarkdownRemark
    const { currentPage } = this.state

    return (
      <PageWrapper>
        <Helmet>
          <title>Events - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div className="section">
                    <HomeLogo />

                    <h1 className="title" id="title-line">
                      Events
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <Pagination
              currentPage={currentPage}
              total={events.length}
              pageCount={5}
              pageValues={events.map((p) => p.fieldValue)}
              handlePageChange={this.handlePageChange}
            />

            <EventPost events={events[currentPage - 1].edges} />
          </div>
        </section>
      </PageWrapper>
    )
  }
}

EventPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const eventPageQuery = graphql`
  query EventPage {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "event-page" } } }
    ) {
      group(field: frontmatter___year) {
        fieldValue
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              cover
              templateKey
              date(formatString: "MMMM DD, YYYY")
              year
              meta_description
            }
          }
        }
      }
    }
  }
`
